<template>
  <div>
    <div class="page-title">
      <h3>Заявки</h3>
      <span>
        <div class="input-field">
          <i class="material-icons prefix">search</i>
          <input id="search" type="text" v-model="search">
          <label for="search">Поиск</label>
        </div>
      </span>
      <span>
        <a class="btn-floating btn-large waves-effect waves-light blue darken-4"
           v-tooltip="'Импортировать'"
           @click="$router.push('/tasks/import')">
          <i class="material-icons">file_upload</i>
        </a>
        &nbsp;&nbsp;&nbsp;
        <a class="btn-floating btn-large waves-effect waves-light red"
           v-tooltip="'Добавить'"
           @click="$router.push('/tasks/add')">
          <i class="material-icons">add</i>
        </a>
      </span>
    </div>

    <div class="row">
      <div class="col s12 m3 l1" v-if="isAdmin">
        <div class="input-field">
          <select id="department_filter" ref="department_select" v-model="department_filter" @change="applyFilter">
            <option value="" selected>Любой</option>
            <option v-for="r in department_list" :key="r.id" :value="r.id">{{ r.name }}</option>
          </select>
          <label for="department_filter">Отдел</label>
        </div>
      </div>

      <div class="col s12 m3 l1">
        <div class="input-field">
          <select id="engineer_filter" ref="engineer_select" v-model="engineer_filter" @change="applyFilter">
            <option value="" selected>Любой</option>
            <option v-for="r in engineer_list.users" :key="r.id" :value="r.id">{{ r.last_name }}
              {{ r.first_name }}
            </option>
          </select>
          <label for="engineer_filter">Инженер</label>
        </div>
      </div>

      <div class="col s12 m3 l2">
        <div class="input-field">
          <select id="team_filter" ref="team_select" v-model="team_filter" @change="applyFilter">
            <option value="" selected>Любая</option>
            <option v-for="r in team_list" :key="r.id" :value="r.id">{{ r.name }}</option>
          </select>
          <label for="team_filter">Бригада</label>
        </div>
      </div>

      <div class="col s12 m3 l1">
        <div class="input-field">
          <input id="object_filter" type="text" v-model="object_input" ref="object_autocomplete" autocomplete="off"
                 @input="findObject">
          <label for="object_filter">Объект</label>
        </div>
      </div>

      <div class="col s12 m3 l1">
        <div class="input-field">
          <select id="type_filter" ref="type_select" v-model="type_filter" @change="applyFilter">
            <option value="" selected>Любой</option>
            <option v-for="r in type_list" :key="r" :value="r">{{ r }}</option>
          </select>
          <label for="type_filter">Тип</label>
        </div>
      </div>

      <div class="col s12 m3 l2">
        <div class="input-field">
          <select id="status_filter" ref="status_select" v-model="status_filter" @change="applyFilter" multiple>
            <option v-for="r in status_list" :key="r.id" :value="r.id">{{ r.name }}</option>
          </select>
          <label for="status_filter">Статус</label>
        </div>
      </div>

      <div class="col s12 m3 l1">
        <div class="input-field">
          <input id="planned_end_time_from" type="text" v-model.lazy="planned_end_time_from_filter" autocomplete="off"
                 class="datepicker" v-datePicker="planned_end_time_from_filter" @change="applyFilter">
          <label for="planned_end_time_from">План.время ОТ</label>
        </div>
      </div>

      <div class="col s12 m3 l1">
        <div class="input-field">
          <input id="planned_end_time_to" type="text" v-model.lazy="planned_end_time_to_filter" autocomplete="off"
                 class="datepicker" v-datePicker="planned_end_time_to_filter" @change="applyFilter">
          <label for="planned_end_time_to">План.время ДО</label>
        </div>
      </div>

      <div class="col s12 m3 l1">
        <div class="input-field">
          <input id="completed_time_from" type="text" v-model.lazy="completed_time_from_filter" autocomplete="off"
                 class="datepicker" v-datePicker="completed_time_from_filter" @change="applyFilter">
          <label for="completed_time_from">Выполнено ОТ</label>
        </div>
      </div>

      <div class="col s12 m3 l1">
        <div class="input-field">
          <input id="completed_time_to" type="text" v-model.lazy="completed_time_to_filter" autocomplete="off"
                 class="datepicker" v-datePicker="completed_time_to_filter" @change="applyFilter">
          <label for="completed_time_to">Выполнено ДО</label>
        </div>
      </div>
    </div>

    <Loader v-if="loading"/>

    <p class="center" v-else-if="!records.length">
      {{ 'Заявок не найдено' }}
    </p>

    <section v-else>
      <TasksTable :records="items" :sortField="sort_field" :sortDirection="sort_direction" :team_list="team_list"
                  @setSorting="onSetSorting"/>

      <Paginate
        v-model="page"
        :page-count="pageCount"
        :click-handler="pageChangeHandler"
        :prev-text="'Назад'"
        :next-text="'Вперед'"
        :container-class="'pagination'"
        :page-class="'waves-effect'"
      />
    </section>
  </div>
</template>

<script>
import paginationMixin from '@/mixins/pagination.mixin'
import TasksTable from '@/components/TasksTable'

export default {
  name: 'tasks',
  metaInfo() {
    return {
      title: this.$title('Заявки')
    }
  },
  mixins: [paginationMixin],
  data: () => ({
    isAdmin: false,
    loading: true,
    records: [],
    department_list: [],
    department_filter: '',
    department_select: null,
    engineer_list: [],
    engineer_filter: '',
    engineer_select: null,
    team_list: [],
    team_filter: '',
    team_select: null,
    object_input: '',
    object_filter: '',
    object_autocomplete: null,
    object_autocomplete_limit: 20,
    status_list: [],
    status_filter: [],
    status_select: null,
    type_list: [],
    type_filter: '',
    type_select: null,
    planned_end_time_from_filter: '',
    planned_end_time_to_filter: '',
    completed_time_from_filter: '',
    completed_time_to_filter: '',
    sort_field: '',
    sort_direction: '',
    search: '',
    searchDebounce: '',
    currentPage: 1,
    pageSize: 50,
  }),
  async mounted() {
    this.isAdmin = await this.$store.dispatch('profile/isAdmin')

    let filter = this.$store.getters["profile/taskFilter"]
    this.department_filter = filter.department_id ? filter.department_id : ''
    this.engineer_filter = filter.engineer_id ? filter.engineer_id : ''
    this.team_filter = filter.team_id ? filter.team_id : ''
    this.type_filter = filter.type ? filter.type : ''
    this.status_filter = filter.status ? filter.status : ['waiting', 'not_started', 'in_progress', 'paused', 'completed', 'verifying']
    this.object_filter = filter.object ? filter.object : ''
    this.object_input = this.object_filter
    this.planned_end_time_from_filter = filter.planned_end_time_from ? filter.planned_end_time_from : ''
    this.planned_end_time_to_filter = filter.planned_end_time_to ? filter.planned_end_time_to : ''
    this.completed_time_from_filter = filter.completed_time_from ? filter.completed_time_from : ''
    this.completed_time_to_filter = filter.completed_time_to ? filter.completed_time_to : ''
    this.sort_field = filter.sort_field ? filter.sort_field : 't.id'
    this.sort_direction = filter.sort_direction ? filter.sort_direction : 'desc'
    this.currentPage = this.$route.query.page ? parseInt(this.$route.query.page) : 1

    if (this.isAdmin) {
      const departments = await this.$store.dispatch('fetchDepartments')
      this.department_list = departments.filter(department => department.id > 1)

      let team_list = await this.$store.dispatch('fetchAllTeams')
      if (team_list.teams !== null) {
        this.team_list = team_list.teams
      }
    } else {
      let team_list = await this.$store.dispatch('fetchTeams', {})
      if (team_list.teams !== null) {
        this.team_list = team_list.teams
      }

      if (!filter) {
        this.engineer_filter = await this.$store.dispatch('profile/getUid')
      }
    }

    this.engineer_list = await this.$store.dispatch('fetchDispatchers')
    this.type_list = await this.$store.dispatch('fetchTypes')

    const status_list = await this.$store.dispatch('fetchStatuses')
    for (const [key, value] of Object.entries(status_list)) {
      this.status_list.unshift({"id": key, "name": value})
    }

    await this.loadResult()

    this.loading = false

    setTimeout(() => {
      this.department_select = M.FormSelect.init(this.$refs.department_select)
      this.engineer_select = M.FormSelect.init(this.$refs.engineer_select)
      this.team_select = M.FormSelect.init(this.$refs.team_select)
      this.status_select = M.FormSelect.init(this.$refs.status_select)
      this.type_select = M.FormSelect.init(this.$refs.type_select)

      this.object_autocomplete = M.Autocomplete.init(this.$refs.object_autocomplete, {
        data: {},
        limit: this.object_autocomplete_limit,
        onAutocomplete: this.setFoundObject,
      })

      M.updateTextFields()
    })
  },
  watch: {
    search: function (val) {
      if (val !== '' && val.length < 3) {
        return
      }

      let DELAY = 2000;
      return this.$inputThrottle(this.applyFilter, DELAY);
    }
  },
  methods: {
    async findObject() {
      if (this.object_input) {
        const object_list = await this.$store.dispatch('findObject', this.object_input, this.object_autocomplete_limit)
        this.object_autocomplete.updateData(object_list)
      } else {
        await this.setFoundObject('')
      }
    },
    async setFoundObject(value) {
      this.object_filter = value
      this.object_input = value
      await this.applyFilter()
    },
    async applyFilter() {
      const filter = {
        department_id: this.department_filter,
        engineer_id: this.engineer_filter,
        team_id: this.team_filter,
        object: this.object_filter,
        status: this.status_filter,
        type: this.type_filter,
        planned_end_time_from: this.planned_end_time_from_filter,
        planned_end_time_to: this.planned_end_time_to_filter,
        completed_time_from: this.completed_time_from_filter,
        completed_time_to: this.completed_time_to_filter,
        sort_field: this.sort_field,
        sort_direction: this.sort_direction,
      }
      this.$store.commit('profile/taskFilter', filter)
      this.currentPage = 1

      if (this.$route.query.page && parseInt(this.$route.query.page) !== this.currentPage) {
        this.$router.push(`${this.$route.path}?page=${this.currentPage}`)
      }

      await this.loadResult()
    },
    async setPaginationPage(num) {
      this.currentPage = num
      await this.loadResult()
    },
    async loadResult() {
      let filter = this.$store.getters["profile/taskFilter"]

      const query = {...filter}
      query.search = this.search
      query.type = query.type ? [query.type] : ''
      query.page = this.currentPage
      query.size = this.pageSize

      const response = await this.$store.dispatch('fetchTasks', query)
      this.records = response.tasks || []
      this.setupPagination(this.setPaginationPage, this.currentPage, 50, this.records, response.count)

      if (response.task_count !== 0) {
        M.toast({html: `Найдено ${response.count} заявок`})
      }
    },
    onSetSorting(field) {
      if (field === this.sort_field) {
        if (this.sort_direction === 'desc') {
          this.sort_direction = 'asc'
        } else {
          this.sort_direction = 'desc'
        }
      }
      this.sort_field = field
      this.applyFilter()
    },
  },
  components: {
    TasksTable
  },
  destroyed() {
    if (this.department_select && this.department_select.destroy) {
      this.department_select.destroy()
    }
    if (this.engineer_select && this.engineer_select.destroy) {
      this.engineer_select.destroy()
    }
    if (this.team_select && this.team_select.destroy) {
      this.team_select.destroy()
    }
    if (this.status_select && this.status_select.destroy) {
      this.status_select.destroy()
    }
    if (this.type_select && this.type_select.destroy) {
      this.type_select.destroy()
    }
  },
}
</script>
