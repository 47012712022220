<template>
  <tr>
    <td class="label" v-bind:class="taskLabel"></td>
    <td class="center-align">
      <router-link :to="{path: '/tasks/' + record.id}"
                   v-tooltip="'Открыть'"
                   class="btn-small btn blue darken-4">
        {{ record.id }}
      </router-link>
      <div v-if="record.external_id">
        <div class="chip no-margin" v-tooltip="'Внешний ID'">{{ record.external_id }}</div>
      </div>
      <div v-if="record.external_status">
        <div class="task_list_categories">{{ record.external_status }}</div>
      </div>
    </td>
    <td>
      <div v-if="record.categories !== ''" class="task_list_categories">{{ getCategories(record.categories) }}</div>
      {{ record.title }}
      <div v-if="record.description !== ''" class="task_list_description">{{ record.description }}</div>
    </td>
    <td>
      <section v-if="record.type">{{ record.type }}</section>
      <section v-else>Нет</section>
    </td>
    <td>
      <span v-if="record.department">
        {{ record.department.name }}
        <!--<a @click="$router.push('/departments/' + record.department.id)">
          {{record.department.name}}
        </a>-->
      </span>
      <span v-else>Нет</span>
    </td>
    <td>
      <section v-if="record.object">
        <div class="valign-wrapper">
          <a
            v-if="record.object.location"
            v-tooltip="'Все ожидающие заявки'"
            class="tiny material-icons"
            style="margin-right: 5px"
            target="_blank"
            @click="showUnassignedTask(record.object)">
            <i class="material-icons">format_list_numbered</i>
          </a>
          <span v-if="record.object.external_id" style="padding-right: 5px">{{ record.object.external_id }}</span>
          (
          <router-link :to="{path: '/objects/' + record.object.id}">
            GSI-{{ record.object.id }}
          </router-link>
          )
        </div>
        <div>{{ record.object.locality }}</div>
        <div>
          {{ record.object.address }}
          <a
            v-if="record.object.location"
            v-tooltip="'Открыть на карте'"
            class="material-icons"
            target="_blank"
            :href="record.object.location | locationLink(17)">
            <i class="tiny material-icons">location_on</i>
          </a>
        </div>
      </section>
      <span v-else>Нет</span>
    </td>
    <td>
      <span v-if="record.engineer">
        {{ record.engineer.last_name }} {{ record.engineer.first_name }}
      </span>
      <span v-else>Нет</span>
    </td>
    <td>
      <div class="input-field" v-if="canChangePlannedTime && canChangeTeam">
        <select id="team_id" ref="team_select" v-model="teamId" @change="changeTeam">
          <option value="" selected>Нет</option>
          <option v-for="r in customTeamList" :key="r.id" :value="r.id">{{ r.name }}</option>
        </select>
      </div>
      <section v-else>
        <span v-if="record.team">
          {{ record.team.name }}
          <!--<a @click="$router.push('/teams/' + record.team.id)">
            {{record.team.name}}
          </a>-->
        </span>
        <span v-else>Нет</span>
      </section>
    </td>
    <td>
      <span v-if="record.object" class="valign-wrapper">
        {{ record.status | status() }}
        <a
          v-if="record.has_report"
          v-tooltip="'Открыть отчет'"
          target="_blank"
          :href="'/tasks/' + record.id + '/report'">
          <i class="material-icons" :class="{'blue-text text-darken-4': record.report_completed, 'red-text text-darken-4': !record.report_completed}">assignment</i>
        </a>
      </span>
    </td>
    <td>
      <div class="input-field" v-if="canChangePlannedTime">
        <input v-bind:id="'planned_end_time_' + record.id" type="text" v-model.lazy="plannedEndTime" class="datepicker"
               autocomplete="off" v-datePicker="plannedEndTime" @change="changePlannedEndTime"
               :class="{invalid: plannedEndTime === ''}">
      </div>
      <section v-else>
        <section v-if="plannedEndTime">{{ plannedEndTime }}</section>
        <section v-else>Нет</section>
      </section>
    </td>
    <td>
      <section v-if="record.external_planned_end_time">
        {{ record.external_planned_end_time.seconds * 1000 | date('datetime') }}
      </section>
      <section v-else>Нет</section>
    </td>
    <td>
      <section v-if="record.done_time">
        {{ record.done_time.seconds * 1000 | date('datetime') }}
      </section>
      <section v-else>Нет</section>
    </td>
  </tr>
</template>

<script>
import dateFilter from "@/filters/date.filter";
import {required} from "vuelidate/lib/validators";
import convertDateString from "@/utils/date";

export default {
  props: {
    record: {
      required: true,
      type: Object
    },
    team_list: {
      required: true,
      type: Array
    },
  },
  data: () => ({
    isAdmin: false,
    teamId: '',
    customTeamList: [],
    teamSelect: null,
    plannedEndTime: '',
  }),
  validations: {
    plannedEndTime: {required},
  },
  mounted() {
    this.isAdmin = this.$store.dispatch('profile/isAdmin')
    this.teamId = this.record.team ? this.record.team.id : ''
    this.plannedEndTime = this.record.planned_end_time ? dateFilter(this.record.planned_end_time.seconds * 1000, 'date') : ''
    this.customTeamList = [...this.team_list]

    if (this.isAdmin) {
      let teamExists = false
      this.customTeamList.forEach(team => {
        if (this.record.team && team.id === this.record.team.id) {
          teamExists = true
        }
      })

      if (this.record.team && !teamExists) {
        this.customTeamList.push(this.record.team)
      }
    }

    setTimeout(() => {
      this.teamSelect = M.FormSelect.init(this.$refs.team_select);
      M.updateTextFields()
    })
  },
  computed: {
    canChangePlannedTime: function () {
      const editableStatuses = ['waiting', 'not_started'];
      const userId = this.$store.dispatch('profile/getUid')

      return editableStatuses.includes(this.record.status) && (this.isAdmin || (this.record.engineer && userId === this.record.engineer.id))
    },
    canChangeTeam: function () {
      return this.plannedEndTime !== ''
    },
    taskLabel: function () {
      if (this.record.status === 'not_started') {
        return 'green lighten-4'
      } else if (this.record.status === 'in_progress') {
        return 'green'
      } /*else if (this.record.status === 'paused') {
        return ''
      } */ else if (this.record.status === 'completed') {
        return 'grey lighten-2'
      } else if (this.record.status === 'verifying') {
        return 'grey'
      } else if (this.record.status === 'closed') {
        return 'grey darken-3'
      }

      if (!this.plannedEndTime) {
        return
      }

      let date = new Date()
      let currentEndDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59))
      let currentBeginDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0))
      let tomorrowEndDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + 1, 23, 59, 59))
      let plannedEndTime = convertDateString(this.plannedEndTime + ' 23:59:59').getTime() / 1000

      if (plannedEndTime > tomorrowEndDate.getTime() / 1000) {
        return
      } else if (plannedEndTime > currentEndDate.getTime() / 1000) {
        return 'yellow'
      } else if (plannedEndTime >= currentBeginDate.getTime() / 1000 && plannedEndTime <= currentEndDate.getTime() / 1000) {
        return 'red lighten-3'
      }

      return 'red darken-4'
    }
  },
  methods: {
    getCategories(categories) {
      if (categories && categories.length > 0) {
        return categories.join(', ')
      }
    },
    async changeTeam() {
      const task = {
        id: this.record.id,
        team_id: this.teamId ? this.teamId : 0,
      }

      await this.$store.dispatch('changeTaskTeam', task)
    },
    async changePlannedEndTime() {
      const plannedEndTime = convertDateString(this.plannedEndTime + ' 23:59:59')

      if (plannedEndTime === null) {
        this.plannedEndTime = ''
        return
      }

      const task = {
        id: this.record.id,
        planned_end_time: {seconds: plannedEndTime.getTime() / 1000},
      }

      await this.$store.dispatch('changeTaskPlannedEndTime', task)

      setTimeout(() => {
        this.teamSelect = M.FormSelect.init(this.$refs.team_select);
        M.updateTextFields()
      })
    },
    showUnassignedTask(object) {
      this.$emit('showUnassignedTaskByObject', object)
    },
  },
  destroyed() {
    if (this.teamSelect && this.teamSelect.destroy) {
      this.teamSelect.destroy()
    }
  }
}
</script>

<style scoped>
th > a {
  text-decoration: none;
}

a.active {
  color: red;
}

td.label {
  width: 3px;
}
</style>
