<template>
  <section>
    <table>
      <thead>
      <tr>
        <th rowspan="2"></th>
        <th class="center-align" rowspan="2" nowrap style="width: 100px">
          <a @click="setSortField('t.id')" v-bind:class="{ active: isSortById }">
            ID
            <i class="tiny material-icons" v-if="sortDirection === 'desc'">arrow_drop_down</i>
            <i class="tiny material-icons" v-if="sortDirection === 'asc'">arrow_drop_up</i>
          </a>
        </th>
        <th rowspan="2" style="width: 30%">Название</th>
        <th rowspan="2" style="width: 4%">Тип</th>
        <th rowspan="2" style="width: 7%">Отдел</th>
        <th rowspan="2" style="width: 13%">
          <a @click="setSortField('o.id')" v-bind:class="{ active: isSortByObject }">
            Объект
            <i class="tiny material-icons" v-if="sortDirection === 'desc'">arrow_drop_down</i>
            <i class="tiny material-icons" v-if="sortDirection === 'asc'">arrow_drop_up</i>
          </a>
        </th>
        <th rowspan="2" style="width: 10%">Инженер</th>
        <th rowspan="2" style="width: 7%">Бригада</th>
        <th rowspan="2" style="width: 5%">
          Статус <a @click="showStatusHelp"><i class="tiny material-icons">info</i></a>
        </th>
        <th colspan="2" class="center-align">Плановая дата</th>
        <th rowspan="2" style="width: 10%">
          <a @click="setSortField('t.completed_time')" v-bind:class="{ active: isSortByDoneTime }">
            Дата выполн.
            <i class="tiny material-icons" v-if="sortDirection === 'desc'">arrow_drop_down</i>
            <i class="tiny material-icons" v-if="sortDirection === 'asc'">arrow_drop_up</i>
          </a>
        </th>
      </tr>
      <tr>
        <th nowrap>
          <a @click="setSortField('t.planned_end_time')" v-bind:class="{ active: isSortByPlannedEndTime }">
            внутренняя
            <i class="tiny material-icons" v-if="sortDirection === 'desc'">arrow_drop_down</i>
            <i class="tiny material-icons" v-if="sortDirection === 'asc'">arrow_drop_up</i>
          </a>
        </th>
        <th nowrap>
          <a @click="setSortField('t.external_planned_end_time')"
             v-bind:class="{ active: isSortByExternalPlannedEndTime }">
            внешняя
            <i class="tiny material-icons" v-if="sortDirection === 'desc'">arrow_drop_down</i>
            <i class="tiny material-icons" v-if="sortDirection === 'asc'">arrow_drop_up</i>
          </a>
        </th>
      </tr>
      </thead>

      <tbody v-for="(record, idx) of records" :key="record.id">
      <TasksTableItem :record="record" :team_list="team_list"
                      v-on:showUnassignedTaskByObject="showUnassignedTaskByObject"/>
      </tbody>
    </table>

    <div id="modal_status_help" class="modal">
      <div class="modal-content">
        <h4>Статусы заявок</h4>
        <p>Не назначена &#8212; заявка без бригады, ожидает назначения инженером</p>
        <p>Ожидание &#8212; назначена бригаде, ожидает выполнения</p>
        <p>В работе &#8212; бригада взяла заявку в работу</p>
        <p>Приостановлена &#8212; бригада приостановила работу над заявкой</p>
        <p>Выполнена &#8212; бригада завершила работу, требуется проверка</p>
        <p>Обработана &#8212; ожидает подверждающих документов</p>
        <p>Закрыта &#8212; закрыта инженером</p>
      </div>
      <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect blue darken-4 btn-flat white-text">Закрыть</a>
      </div>
    </div>

    <div id="modalObjectTasks" class="modal">
      <div class="modal-content" v-if="canShowObjectTasks">
        <h4>Список ожидающих заявок для объекта {{ getObjectName(selectedObject) }}</h4>
        <h6>{{ selectedObject.locality }}, {{ selectedObject.address }}</h6>
        <table>
          <thead>
          <tr>
            <th rowspan="2">
              <div class="input-field">
                <label>
                  <input type="checkbox" class="filled-in blue darken-4" v-model="unassignedTaskAll"/>
                  <span></span>
                </label>
              </div>
            </th>
            <th rowspan="2" class="center-align">ID</th>
            <th rowspan="2">Название</th>
            <th rowspan="2">Тип</th>
            <th rowspan="2">Бригада</th>
            <th colspan="2" class="center-align">Плановая дата завершения</th>
          </tr>
          <tr>
            <th class="center-align">внутренняя</th>
            <th class="center-align">внешняя</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="(record, idx) of objectTasks" :key="record.id">
            <td>
              <label>
                <input :id="'unassigned_task_'.concat(record.id)" :value="record" type="checkbox"
                       class="filled-in blue darken-4" v-model="selectedUnassignedTasks"/>
                <span></span>
              </label>
            </td>
            <td class="center-align">
              <a v-bind:href="('/tasks/' + record.id)" target="_blank" v-tooltip="'Открыть'"
                 class="btn-small btn blue darken-4">
                {{ record.id }}
              </a>
              <div v-if="record.external_id">
                <div class="chip no-margin" v-tooltip="'Внешний ID'">{{ record.external_id }}</div>
              </div>
            </td>
            <td>
              {{ record.title }}
              <div v-if="record.description !== ''" class="task_list_description">{{ record.description }}</div>
            </td>
            <td>
              <section v-if="record.type">{{ record.type }}</section>
              <section v-else>Нет</section>
            </td>
            <td>
              <span v-if="record.team">
                {{ record.team.name }}
              </span>
              <span v-else>Нет</span>
            </td>
            <td>
              <section v-if="record.planned_end_time">
                {{ record.planned_end_time.seconds * 1000 | date('date') }}
              </section>
              <section v-else>Нет</section>
            </td>
            <td>
              <section v-if="record.external_planned_end_time">
                {{ record.external_planned_end_time.seconds * 1000 | date('datetime') }}
              </section>
              <section v-else>Нет</section>
            </td>
          </tr>
          <tr>
            <td>
              <label>
                <input type="checkbox" class="filled-in blue darken-4" v-model="unassignedTaskAll"/>
                <span></span>
              </label>
            </td>
            <td colspan="3">
            <td>
              <select id="unassigned_team_select" ref="unassignedTeamSelect" v-model="unassignedTeam">
                <option value="" selected>Нет</option>
                <option v-for="r in customTeamList" :key="r.id" :value="r">{{ r.name }}</option>
              </select>
            </td>
            <td>
              <input id="unassigned_planned_end_time" type="text" v-model.lazy="unassignedPlannedEndTime"
                     class="datepicker" autocomplete="off" v-datePicker="unassignedPlannedEndTime"
                     :class="{invalid: $v.unassignedPlannedEndTime.$error}">
            </td>
            <td>
              <button class="btn waves-effect waves-light blue darken-4" type="submit" @click="assignUnassignedTask">
                Сохранить
                <i class="material-icons right">send</i>
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect blue darken-4 btn-flat white-text">Закрыть</a>
      </div>
    </div>
  </section>
</template>

<script>
import TasksTableItem from "@/components/TasksTableItem";
import convertDateString from "@/utils/date";
import {required} from "vuelidate/lib/validators";
import dateFilter from "@/filters/date.filter";

export default {
  components: {TasksTableItem},
  props: {
    records: {
      required: true,
      type: Array
    },
    sortField: {
      required: true,
      type: String,
    },
    sortDirection: {
      required: true,
      type: String,
    },
    team_list: {
      required: true,
      type: Array
    },
  },
  data: () => ({
    isAdmin: false,
    modalStatusHelp: null,
    modalObjectTasks: null,
    objectTasks: [],
    selectedObject: null,
    selectedUnassignedTasks: [],
    unassignedTaskAll: false,
    unassignedTeam: '',
    unassignedTeamSelect: null,
    unassignedPlannedEndTime: '',
    customTeamList: [],
  }),
  validations: {
    unassignedPlannedEndTime: {required},
  },
  mounted() {
    this.isAdmin = this.$store.dispatch('profile/isAdmin')
    this.customTeamList = [...this.team_list]

    let date = new Date()
    date.setDate(date.getDate())
    this.unassignedPlannedEndTime = dateFilter(date.getTime(), 'date')

    this.modalStatusHelp = M.Modal.init(document.querySelector('#modal_status_help'));
    this.modalObjectTasks = M.Modal.init(document.querySelector('#modalObjectTasks'));
  },
  computed: {
    isSortById: function () {
      return this.sortField === 't.id'
    },
    isSortByObject: function () {
      return this.sortField === 'o.id'
    },
    isSortByPlannedEndTime: function () {
      return this.sortField === 't.planned_end_time'
    },
    isSortByExternalPlannedEndTime: function () {
      return this.sortField === 't.external_planned_end_time'
    },
    isSortByDoneTime: function () {
      return this.sortField === 't.completed_time'
    },
    canShowObjectTasks: function () {
      return this.selectedObject != null
    },
  },
  watch: {
    unassignedTaskAll: function (current) {
      if (current === false) {
        this.selectedUnassignedTasks = []
        return
      }

      this.selectedUnassignedTasks = []
      this.objectTasks.forEach(task => this.selectedUnassignedTasks.push(task));
    }
  },
  methods: {
    showStatusHelp() {
      this.modalStatusHelp.open();
    },
    getCategories(categories) {
      if (categories && categories.length > 0) {
        return categories.join(', ')
      }
    },
    setSortField(field) {
      this.$emit('setSorting', field)
    },
    async showUnassignedTaskByObject(object) {
      this.selectedObject = object

      const payload = {
        object: "GSI" + object.id.toString(),
        status: ['waiting', 'not_started'],
        sort_field: 't.id',
        sort_direction: 'desc',
      }

      if (!this.isAdmin) {
        payload['engineer_id'] = await this.$store.dispatch('profile/getUid')
      }

      let response = await this.$store.dispatch('fetchTasks', payload)
      this.objectTasks = response.tasks

      this.modalObjectTasks.open();
      this.unassignedTeamSelect = M.FormSelect.init(this.$refs.unassignedTeamSelect);
      M.updateTextFields()
    },
    getObjectName(obj) {
      if (!obj) {
        return ""
      }

      if (obj.external_id) {
        return obj.external_id + ' (GSI ' + obj.id + ')'
      } else {
        return obj.id
      }
    },
    assignUnassignedTask() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      if (this.selectedUnassignedTasks.length === 0) {
        return
      }

      const plannedTime = convertDateString(this.unassignedPlannedEndTime + ' 23:59:59')

      if (plannedTime === null) {
        return
      }

      this.selectedUnassignedTasks.forEach(async function (task) {
        const t = task
        t.team = this.unassignedTeam ? this.unassignedTeam : null
        t.planned_end_time = {seconds: plannedTime.getTime() / 1000}
        t.engineer = {"id": await this.$store.dispatch('profile/getUid')}

        const res = await this.$store.dispatch('updateTask', t)

        if (res && res.id) {
          task = t
        }
      }, this)

      this.selectedUnassignedTasks = []
      this.unassignedTeam = null
      this.unassignedTaskAll = false
    },
  },
  destroyed() {
    this.modalStatusHelp.destroy();
    this.modalObjectTasks.destroy();

    if (this.unassignedTeamSelect && this.unassignedTeamSelect.destroy) {
      this.unassignedTeamSelect.destroy()
    }
  }
}
</script>

<style scoped>
th > a {
  text-decoration: none;
}

a.active {
  color: red;
}
</style>
